/** @format */

import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/fr';
import React from 'react';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import Auth from './auth/Auth';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
  },
  padding: {
    [theme.breakpoints.up('md')]: {
      marginBottom: -80,
      //      paddingBottom: 80,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: -200,
      paddingBottom: 200,
    },
    paddingTop: 30,
  },
  loadingWrapper: {
    pointerEvents: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 99999,
    '& ._loading_overlay_overlay': {
      zIndex: 99999,
      // backgroundImage: 'linear-gradient(136deg, #408b6a 0%, #50AE85 50%, #72be9d 100%)',
      backgroundColor: theme.palette.primary.main,
    },
    '& ._loading_overlay_content': {
      fontSize: 26,
    },
    '& ._loading_overlay_spinner': {
      width: 100,
      '& svg > circle': {
        strokeWidth: 3,
      },
    },
  },
  variantSuccess: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  variantError: {
    backgroundColor: `${theme.palette.error.main} !important`,
  },
  variantInfo: {
    backgroundColor: `${theme.palette.info.main} !important`,
  },
  variantWarning: {
    backgroundColor: `${theme.palette.warning.main} !important`,
  },
  alertIcon: {
    '--fa-primary-color': theme.palette.warning.dark,
    '--fa-secondary-color': theme.palette.warning.light,
    '--fa-primary-opacity': 1,
    '--fa-secondary-opacity': 1,
  },
}));

function Main() {
  const classes = useStyles();

  moment.locale('fr');

  return (
    <>
      <CssBaseline />
      <BrowserRouter>
        <div
          className={clsx(
            classes.root,
          )}
        >
          <div>
            <Routes>
              <Route path="*" element={<Auth />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
}

Main.propTypes = {};

export default Main;
