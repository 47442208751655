/** @format */

import '@fontsource/public-sans/300.css';
import '@fontsource/public-sans/400.css';
import '@fontsource/public-sans/500.css';
import '@fontsource/public-sans/600.css';
import '@fontsource/public-sans/700.css';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider as ProLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React from 'react';
import { createRoot } from 'react-dom/client';
import Main from './components/Main';
import { mainTheme } from './themes';

/* Fix for Google Maps default font */
const head = document.getElementsByTagName('head')[0];
const { insertBefore } = head;
head.insertBefore = (newElement, referenceElement) => {
  if (newElement.href &&
    newElement.href.indexOf('https://fonts.googleapis.com/css?family=Roboto',) === 0) {
    return;
  }
  insertBefore.call(head, newElement, referenceElement);
};

const resizeErrors = [
  'ResizeObserver loop completed with undelivered notifications.', // Firefox
  'ResizeObserver loop limit exceeded', // Chrome
  'ResizeObserver loop completed with undelivered notifications.', // Safari
];

window.addEventListener('error', (e) => {
  if (resizeErrors.includes(e.message)) {
    e.stopImmediatePropagation();
  }
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<StyledEngineProvider injectFirst>
    <ThemeProvider theme={mainTheme}>
      <ProLocalizationProvider dateAdapter={AdapterMoment}>
        <Main />
      </ProLocalizationProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
);
