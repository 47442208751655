/** @format */

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import image from '../../assets/images/backgrounds/solar_panels_1.jpg';
import Login from './Login';

const useStyles = makeStyles({
  image: {
    height: '100vh',
    minWidth: '100%',
    minHeight: '100%',
    background: 'no-repeat center bottom',
    backgroundSize: 'cover',
  },
});

/**
 * Auth component
 */
function Auth() {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Container maxWidth="xs">
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="*" element={<Navigate to="login" replace />} />
          </Routes>
        </Container>
      </Grid>
      <Grid item xs={12} md={6}>
        <div
          style={{ backgroundImage: `url(${image})` }}
          className={classes.image}
        />
      </Grid>
    </Grid>
  );
}

export default Auth;
